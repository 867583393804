var EducationalPoints = {
    data() {
        return {}
    },
    created() {
        if (document.body.contains(document.getElementById('eduPoints'))) {
            this.scrollableSection();

            document.addEventListener('load', this.scrollableSection);
            document.addEventListener('scroll', this.scrollableSection);
            document.addEventListener('resize', this.scrollableSection);
        }
    },
    methods: {
        scrollableSection() {
            const navbar = document.getElementById('navbar')
            const BannerImage = document.getElementById('BannerImage');
            const container = document.getElementById('eduPoints');

            const topOffset = (window.pageYOffset + navbar.clientHeight);
            const bottomOfContainer = (container.offsetTop + container.clientHeight - 25);
            const bottomOfBanner = (BannerImage.scrollTop + BannerImage.clientHeight + 25);

            if (window.innerWidth >= 1200) {
                if ((window.pageYOffset + navbar.clientHeight) > BannerImage.offsetTop) {
                    BannerImage.style.position = 'fixed';
                    BannerImage.style.top = navbar.offsetHeight + 'px';
                }

                if ((window.pageYOffset + navbar.clientHeight) < BannerImage.offsetTop + 20) {
                    BannerImage.style.position = '';
                    BannerImage.style.top = '';
                }

                if ((topOffset + bottomOfBanner) > bottomOfContainer + 10) {
                    BannerImage.style.position = 'absolute';
                    BannerImage.style.top = (bottomOfContainer - bottomOfBanner) + 'px';
                }
            } else {
                BannerImage.removeAttribute('style');
            }
        }        
    }
}