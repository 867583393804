const mixins = [
    progressScroll, 
    responsiveViewport,
    Form,
    ScrollMenuAdditional,
    ScrollTop,
    ScrollSidebar,
    WebinarsList,
    WebinarItem,
    EducationalPoints,
    QnA
]

var vm = new Vue({
    delimiters: ['${', '}'],
    mixins: mixins,
    data() {
        return {
            hasOptionSelected: false,
            menuExpand: false,
        }
    },
    created() {
        window.addEventListener('load', () => {
            if(this.isUserPatient || this.isUserDoctor) {
                this.hasOptionSelected = true;
            } else {
                this.hasOptionSelected = false;
            }
        });
    },
    computed: {        
        isUserPatient() {
            const role = sessionStorage.getItem('_userAccess');

            if (role === 'patient') { 
                return role;
            }
        },
        isUserDoctor() {
            const role = sessionStorage.getItem('_userAccess');

            if (role === 'doctor') {
                return role;
            }
        },        
    },
    methods: {
        userRoleSelect(role) {
            switch(role) {
                case 'patient':
                    sessionStorage.setItem('_userAccess', role);
                    break;
                case 'doctor':
                    sessionStorage.setItem('_userAccess', role);
                    break;
                default:                
                    return;
            }
            
            let userAccess = sessionStorage.getItem('_userAccess');

            if(userAccess !== role) {                
                this.hasOptionSelected = false;
            }
            this.hasOptionSelected = true;

            window.location.reload();
        },
        triggerMobileMenu() {
            const $body = document.body.classList;
            const brand = document.getElementById('brand');

            this.menuExpand = !this.menuExpand;

            if(this.menuExpand) {
                $body.add('disable-scroll') 
                brand.style.pointerEvents = 'none'
            } else{
                $body.remove('disable-scroll');
                brand.style.pointerEvents = ''
            }            
        },
        linkDisabled(e) {
            e.preventDefault();
            return false;
        }, 
    }
})

vm.$mount('#app');