'use strict';

var textOrphans = function () {
    $('p, h1').each(function () {

        if ($(this).html().match(/class="order"/g)) return;

        var tekst = $(this).html();
        tekst = tekst.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, "$1$2&nbsp;"); //dwuznakowe
        $(this).html(tekst);
    });
};

var cookies = function () {
    if ($.cookie('giodoCookies') !== '1') {
        setTimeout(function () {
            $('.cookies-alert').fadeIn(500);
        }, 1000)
    }
    $('.cookies-alert button').on('click', function () {
        $('.cookies-alert').fadeOut(500, function () {
            $.cookie('giodoCookies', '1', {
                expires: 365
            });
        });
    });
};

var rotator = function () {
    var swiper = new Swiper('.swiper-container', {
        slidesPerView: 1,
        speed: 600,        
        centeredSlides: false,
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            init: function() {
                if(this.isBeginning) {
                    $('.swiper-button-prev').css({ opacity: '0', pointerEvents: 'none'})                 
                }
            },
            slideChange: function() {               
                if(!this.isBeginning || !this.isEnd) {
                    $('.swiper-button-prev').css({ opacity: '', pointerEvents: '' })                 
                    $('.swiper-button-next').css({ opacity: '', pointerEvents: '' })                 
                }
                if(this.isBeginning) {
                    $('.swiper-button-prev').css({ opacity: '0', pointerEvents: 'none'})
                }
                if(this.isEnd) {
                    $('.swiper-button-next').css({ opacity: '0', pointerEvents: 'none'})
                }                
            }
        }
    });
}

$(document).on('click', '.smooth', function (e) {
    e.preventDefault();

    var hash = $(this).attr('href');
    var el = $(hash);
    var navbar = $('nav').height();
    var $offset = Math.round(navbar + 250);

    $('html, body').animate({
        scrollTop: el.offset().top - $offset
    }, 1000)
})

$(function () {
    cookies();
    textOrphans();
    rotator();
})