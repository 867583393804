const ScrollTop = {    
    created() {
        this.showScrollToTop();
        document.addEventListener('scroll', this.showScrollToTop)
    },
    destroyed() {
        document.removeEventListener('scroll', this.showScrollToTop)
    },
    methods: {
        showScrollToTop() {
            const scrollTrigger = document.getElementById('scroll-top')
            const navbar = document.getElementById('navbar')
            const height = (window.pageYOffset + navbar.clientHeight);

            if(document.body.contains(scrollTrigger)) {
                if(height > 360) {
                    scrollTrigger.classList.add('scroll-up-visible')
                } else {
                    scrollTrigger.classList.remove('scroll-up-visible')
                }
            }

        },
        scrollTop() {
            $('html, body').animate({
                scrollTop: $('body').offset().top
            }, 1000)
        }
    }
}