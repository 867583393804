var progressScroll = {
    data() {
        return{
            value: 0,
        }
    },
    created() {
        document.addEventListener('scroll',  this.progressScrollBar);
    },
    destroyed() {
        document.removeEventListener('scroll', this.progressScrollBar);
    },
    methods: {
        progressScrollBar() {
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrollbar = document.getElementById('scroll-progress-bar');
            this.value = (winScroll / height) * 100;

            scrollbar.style.width = `${this.value}%`;
        }
    }
}