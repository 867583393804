const ScrollMenuAdditional = {
    data() {
        return {
            hasFloatMenu: false,
            scrollPos: 0
        }
    },
    created() {
        this.menuOffsetTop();        
        document.addEventListener('scroll', this.menuOffsetTop)
        window.addEventListener('resize', this.menuOffsetTop)
    },
    destroyed() {
        document.removeEventListener('scroll', this.menuOffsetTop)
        window.removeEventListener('resize', this.menuOffsetTop)
    },
    methods: {       
        menuOffsetTop() {
            const menu = document.getElementById('block-body-menu');
            const entryArticle = document.getElementById('entry_article');
            const navbarHeight = document.getElementById('navbar');
            const menuFixed = document.getElementById('stickymenu');

            if(document.body.contains(menu)) {
                this.hasFloatMenu = true;    
            } else {
                this.hasFloatMenu = false;
            }

            if(this.hasFloatMenu) {
                if(window.innerWidth > 992) {                    
                    if((window.pageYOffset + (navbarHeight.clientHeight)) > (entryArticle.offsetTop + entryArticle.clientHeight) + 250) {
                        if((document.body.getBoundingClientRect()).top > this.scrollPos) {
                            menuFixed.classList.add('sticky-visible')
                            menuFixed.style.top = navbarHeight.clientHeight+'px'
                        } else {                          
                            menuFixed.classList.remove('sticky-visible')
                            menuFixed.style.top = ''
                        }
                    } else {
                        menuFixed.classList.remove('sticky-visible')
                        menuFixed.style.top = ''
                    }
                                                    
                    this.scrollPos = (document.body.getBoundingClientRect()).top
                }
            }
        },
    }
}