const WebinarsList = {
    data() {
        return {
            featured: null,
            webinarsList: [],
            webinarsLoaded: false,
            featuredColumnHeight: null
        }
    },
    created() {
        this.loadWebinars()
        document.addEventListener('load', this.loadWebinars)

        if(document.body.contains(document.getElementById('featuredItem'))) {
            window.addEventListener('scroll', this.stickyFeaturedItem);
            window.addEventListener('resize', this.stickyFeaturedItem);
        }
    },
    methods: {
        stickyFeaturedItem: function() {
            const featured = document.getElementById('featuredItem');
            const featuredWidth = document.getElementById('featuredStatic');
            const list = document.getElementById('webinarsList');
            const navbarHeight = document.getElementById('navbar');            

            if(window.innerWidth > 1200) {                
                if((window.pageYOffset + navbarHeight.clientHeight) >= list.getBoundingClientRect().top)  {
                    featured.style.position = 'fixed';
                    featured.style.top = (navbarHeight.clientHeight + featuredWidth.offsetTop + 25)+'px';
                    featured.style.bottom = 'auto';
                    featured.style.width = featuredWidth.clientWidth + 'px';
                } else {
                    featured.style.position = '';
                    featured.style.top = '';
                    featured.style.width = '';
                } 

                if((window.pageYOffset + featured.clientHeight) > (list.scrollTop + list.clientHeight) + 75) {
                    featured.style.position = 'absolute';
                    featured.style.top = 'auto';
                    featured.style.bottom = '0';
                }
            } else {
                featured.removeAttribute('style');
            }
        },
        loadWebinars() {
            this.webinarsLoaded = false;
            axios.get('/webinars')
                .then(response => {           
                    let list;        

                    this.webinarsLoaded = true;
                    list = response.data;

                    this.loadRemainingWebinars(list)                
                    this.loadRecentWebinar(list[0]);
                })
                .catch(error => {
                    // console.log(error.response)
                })
        },
        loadRecentWebinar(webinar) {
            this.featured = webinar;
        },
        loadRemainingWebinars(list) {
            const all = list;
            const remaining = all.slice(1);

            this.webinarsList = remaining;
        }
    }
}