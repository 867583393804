const ScrollSidebar = {
    data() {
        return {
            sidebarScroll: false,
            materials: [],
            materialsLoaded: false,
            isScrolling: false
        }
    },
    created() {
        this.fetchMaterials();   
        if(document.body.contains(document.getElementById('materialsPage'))) {
            this.downloadSidebar();  
            document.addEventListener('scroll', this.downloadSidebar);
            document.addEventListener('resize', this.downloadSidebar);
        }
    },
    methods: {
        fetchMaterials() {
            this.materialsLoaded = false;

            if(document.body.contains(document.getElementById('patient-materials'))) {
                axios.get('/materials')
                .then(r => {
                    this.materialsLoaded = true;
                    
                    this.materials = r.data;                    
                })
                .catch(err => {
                    // console.log(err.response)
                })
            }

        },
        downloadSidebar() {
            const base = document.getElementById('header_base');
            const sidebar = document.getElementById('patient-materials');
            const navbarHeight = document.getElementById('navbar');
            const spacerWidth = document.querySelector('.clear-spacer');
            const lastSection = document.getElementById('materialsSectionLast');
            const formSection = document.getElementById('contact_form');
            const contentWrapper = document.getElementById('materialsPage');
            
            const offsetTopPos = (window.pageYOffset + navbarHeight.clientHeight);
            const bottomOfSidebar = (sidebar.scrollTop + sidebar.clientHeight + 50);
            const bottomPos = ((formSection.scrollTop + contentWrapper.clientHeight) - lastSection.clientHeight + 30);
            const bottomOfContentWrapper = (contentWrapper.offsetTop + contentWrapper.clientHeight - 25);

            if(document.body.contains(sidebar)) {
                this.sidebarScroll = true;    
            } else {
                this.sidebarScroll = false;
            }

            if(this.sidebarScroll) {
                if(window.innerWidth > 992) {

                    if((window.pageYOffset + navbarHeight.clientHeight) > base.offsetTop)  {                        
                        sidebar.classList.add('sticky');
                        sidebar.style.top = (navbarHeight.clientHeight + spacerWidth.offsetTop + 25)+'px';
                        sidebar.style.width = spacerWidth.clientWidth+'px';                      
                    }
                    else {
                        sidebar.classList.remove('sticky');
                        sidebar.style.top = '0px';
                        sidebar.style.width = '';
                    }                                        

                    if((offsetTopPos + bottomOfSidebar) > bottomOfContentWrapper) {
                        sidebar.style.position = 'absolute';                
                        sidebar.style.top = bottomPos +'px';
                        sidebar.style.zIndex = '1';
                    } else {
                        sidebar.style.position = ''; 
                        sidebar.style.zIndex = '';
                    }
                } else {
                    sidebar.removeAttribute('style');
                    sidebar.classList.remove('sticky');
                }
            }

        }
    }
}