var responsiveViewport = {
    data() {
        return {
            window: {
                width: 0
            },
            largeScreen: false,
            isVisible: false,
            isSmallScreen: false
        }
    },
    created() {
        window.addEventListener('resize', this.handleMediaQuery)
        this.handleMediaQuery();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleMediaQuery)
    },
    methods: {
        handleMediaQuery() {
            const $body = document.body.classList;
            const brand = document.getElementById('brand');

            this.window.width = window.innerWidth;

            if(this.window.width < 1200) {
                this.isSmallScreen = true
            } else {
                this.isSmallScreen = false
            }

            if(this.window.width > 1200) {
                this.isVisible = true
            } else {
                this.isVisible = false
            }
            
            if(this.window.width <= 992) {
                this.largeScreen = false
            }
            if(this.window.width > 992) {
                this.largeScreen =  true;
                this.menuExpand = false;
                $body.remove('disable-scroll');
                brand.style.pointerEvents = ''
            }

            return this.window.width;
        }
    }
}