const QnA = {    
    methods: {
        QAExpand(id) {            
            const accordionItem = document.querySelectorAll('.qa-collection-tab-panel-content');
            
            accordionItem.forEach((item) => {
                const parent = item.parentElement;
                const text = document.querySelector('.panel-text[data-block-id="'+id+'"]');

                if(item.dataset.questionId.match(id)) {
                    item.classList.toggle('qa-panel-show');
                    parent.classList.toggle('qa-expanded');

                    if(text.dataset.blockId === id) {
                        parent.classList.contains('qa-expanded') ? text.innerHTML = 'Zwiń' : text.innerHTML = 'Rozwiń odpowiedź';
                    }                    
                }
            });
        }        
    }
}