var Form = {
    data() {
        return {
            formMessage: '',
            formSignature: '',
            formButtonActive: false,
            formSubmitted: false,
            formSending: false
        }
    },
    created() {},
    computed: {
        validated() {
            if(this.formMessage) {
                this.formButtonActive = true;
            } else {
                this.formButtonActive = false;
            }

            return this.formButtonActive;
        },
    },
    methods: {
        preventLeadingSpace(e) {
            if (!e.target.value) { 
                e.preventDefault();
            }
            else if (e.target.value[0] === '') {
                e.target.value = e.target.value.replace(/^\s*/, "");
            }
        },   
        submitCompleted(complete) {
            this.formSignature = '';
            this.formMessage = '';

            if(complete) {
                this.isSubmitted = false
            }
        },     
        handleSubmit() {
            this.formSending = true;

            if(this.formMessage && this.formSignature) {
                this.handleResponse(this.formMessage, this.formSignature);
            } else {
                if(this.formMessage && this.formSignature === '' || this.formSignature.length === 0) {
                    this.formSignature = 'Anonimowy Użytkownik';
                }

                this.handleResponse(this.formMessage, this.formSignature);
            }            
        },
        handleResponse(question, name) {
            axios.post('/questions', {
                question: question,
                name: name
            })
            .then(response => {                
                this.formSending = false;
                this.formSubmitted = true;

                this.submitCompleted(this.formSubmitted);
            })
            .catch(error => console.log(error.response));
        },
        resetForm() {
            if(this.formSubmitted) {
                this.formSubmitted = false;
                this.formSent = false;
            }
        }
    }
}