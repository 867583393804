const WebinarItemVideo = Vue.component('webinarvideo', {
    props: [
        'dataid', 
        'datawidth'
    ],
    template: 
    `
        <div id="gerd_webinar" 
            :data-vimeo-id="dataid" 
            :data-vimeo-width="datawidth" 
            data-vimeo-responsive="true" 
            data-vimeo-title="false" 
            data-vimeo-byline="false"
            class="media-player-banner">
        </div>
    `
})

const WebinarItem = {
    components: {
        WebinarItemVideo
    },
    data() {
        return {
            webinar: null,
            isWebinarLoaded: false,
            iframeWidth: null,
            mediaReady: false
        }
    },
    created() {
        if(document.body.contains(document.getElementById('webinar_item'))) {
            document.addEventListener('load', this.loadSelectedWebinar)
            this.loadSelectedWebinar()
    
            window.addEventListener('resize', this.setIframeWidth)
            this.setIframeWidth()
        }
    },
    destroyed() {
            window.removeEventListener('resize', this.setIframeWidth)
    },
    methods: {
        setIframeWidth() {
            const mediaContainer = document.querySelector('.media-details-video');
            this.iframeWidth = mediaContainer.clientWidth
        },
        videoIframe() {                   
            if(this.isWebinarLoaded) {
                setTimeout(() => {
                    var webinarPlayer = new Vimeo.Player('gerd_webinar');
                }, 1000)
            }
        },
        loadSelectedWebinar() {
            const $slug = location.pathname;

            this.isWebinarLoaded = false;

            axios.get('/webinars')
                .then(response => {
                    const data = response.data
                    data.map((item) => {
                        if (item.slug === $slug.slice(9)) {
                            this.isWebinarLoaded = true
                            this.webinar = item;
                            this.videoIframe();
                        }
                    })
                })
                .catch(error => {
                    // console.log(error.response)
                })
        }
    }
}